import Range, { FilterState } from "../../types/filter";
import { distinct, maxNumber, minNumber } from "../../utils/utils";
import sortOptions, { sortEstates } from "../../types/sort";

import OnOfficeEstate from "../../types/estate";
import OnOfficeEstatesQuery from "../../queries/onOfficeEstates";
import OnOfficeFieldsQuery from "../../queries/onOfficeFields";
import { PER_PAGE } from "../estates/Pagination";
import React from "react";
import { reducer } from "./reducer";

export const GlobalStateContext = React.createContext(undefined);
export const GlobalDispatchContext = React.createContext(undefined);

export const DEFAULT_ORDERING = sortOptions[1];

const GlobalContextProvider = ({ children }) => {
  const fields = OnOfficeFieldsQuery();
  const onOfficeEstates: OnOfficeEstate[] = sortEstates(
    OnOfficeEstatesQuery().filter(
      (obj) => obj.ind_2038_Feld_ObjTech66 !== "ind_Schl_3023"
    ), // not referenz
    DEFAULT_ORDERING.key
  );
  const kindOptions = distinct(onOfficeEstates.map((obj) => obj.objektart)),
    saleOptions = distinct(onOfficeEstates.map((obj) => obj.vermarktungsart)),
    typeOptions = distinct(onOfficeEstates.map((obj) => obj.objekttyp)),
    m2Options = onOfficeEstates.map((obj) => obj.wohnflaeche),
    totalM2Options = onOfficeEstates.map((obj) => obj.gesamtflaeche),
    roomCntOptions = onOfficeEstates
      .map((obj) => obj.anzahl_zimmer)
      .sort((a, b) => Number(a) - Number(b)),
    priceOptions = onOfficeEstates.map((obj) => obj.kaufpreis),
    rentOptions = onOfficeEstates.map((obj) => obj.kaltmiete),
    propertyM2Options = onOfficeEstates.map((obj) => obj.grundstuecksflaeche);

  let locationOptions = onOfficeEstates
      .map((obj) => obj.ort)
      .sort();
  // @ts-ignore
  locationOptions = [...new Set(locationOptions)]     // delete double location entries from array

  const m2Range: Range = {
      min: minNumber(m2Options),
      max: maxNumber(m2Options),
    },
    totalM2Range: Range = {
      min: minNumber(totalM2Options),
      max: maxNumber(totalM2Options),
    },
    roomCntRange: Range = {
      min: minNumber(roomCntOptions),
      max: maxNumber(roomCntOptions),
    },
    priceRange: Range = {
      min: minNumber(priceOptions),
      max: maxNumber(priceOptions),
    },
    rentRange: Range = {
      min: minNumber(rentOptions),
      max: maxNumber(rentOptions),
    },
    propertyM2Range: Range = {
      min: minNumber(propertyM2Options),
      max: maxNumber(propertyM2Options),
    };
  const initialState: FilterState = {
    fields: fields,
    kindOptions: kindOptions,
    kindSelection: [],
    saleOptions: saleOptions,
    saleSelection: [],
    typeOptions: typeOptions,
    typeSelection: [],
    m2Range: m2Range,
    m2Start: [m2Range.min, m2Range.max],
    totalM2Range: totalM2Range,
    totalM2Start: [totalM2Range.min, totalM2Range.max],
    roomCntRange: roomCntRange,
    roomCntStart: [roomCntRange.min, roomCntRange.max],
    priceRange: priceRange,
    priceStart: [priceRange.min, priceRange.max],
    rentRange: rentRange,
    rentStart: [rentRange.min, rentRange.max],
    propertyM2Range: propertyM2Range,
    propertyM2Start: [propertyM2Range.min, propertyM2Range.max],
    sortOptions: sortOptions,
    sortOption: DEFAULT_ORDERING,
    initialEstates: onOfficeEstates,
    filteredEstates: onOfficeEstates,
    total: onOfficeEstates.length,
    currentPage: 1,
    estatePage: onOfficeEstates.slice(1 * PER_PAGE - PER_PAGE, 1 * PER_PAGE),
    locationOptions: locationOptions,
    locationSelection: [],
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
